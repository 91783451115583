import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/homeWeb.vue')
  },
  {
    path: '/resumePreview',
    name: 'resumePreview',
    component: () => import('@/pages/resumePreviewWeb.vue')
  },
  {
    path: '/preView',
    name: 'preViewWeb',
    component: () => import('@/pages/preViewWeb.vue')
  },
  {
    path: '/phoneindex',
    name: 'homePhone',
    component: () => import('@/pages/homePhone.vue')
  },
  {
    path: '/m.resumePreview',
    name: 'resumePreviewH5',
    component: () => import('@/pages/resumePreviewPhone.vue')
  },
  {
    path: '/m.preView',
    name: 'preViewPhone',
    component: () => import('@/pages/preViewPhone.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})




export default router
