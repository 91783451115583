import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router';
import '@/assets/css/reset.css'
import '@/assets/css/public.css'
import Axios from 'axios'
import pinia from './store';


// ElementPlus
import ElementPlus from 'element-plus' //全局引入
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'


const app = createApp(App)

app.config.productionTip = false
Axios.defaults.baseURL = '/api'
app.use(pinia).use(router).use(ElementPlus, {
  locale: zhCn,
}).mount('#app')
